<template>
    <div class="container" id="flow6">
        <div class="main">
            <ul class="footer_box">
                <li class="item_start">
                    <div class="logo">
                        <img src="../assets/logo.png" alt="">
                    </div>
                    <p class="desc">
                        Open a magical journey and achieve<br>unimaginable things with CZ COIN
                    </p>
                    <div class="iconLink">
                        <a href="https://x.com/FreeCZ_Binance">
                            <img src="../assets/Link1.png" alt="">
                        </a>
                        <a href="https://t.me/FreeCZ_Bin4nce">
                            <img src="../assets/Link2.png" alt="">
                        </a>
                    </div>
                </li>
                <li class="links">
                    <dl>
                        <dt class="pb-10">Quick link</dt>
                        <dd>
                            <a href="#flow2">Tokenomics</a>
                        </dd>
                        <dd>
                            <a href="#flow3">Partners</a>
                        </dd>
                        <dd>
                            <a href="#flow4">Roadmap</a>
                        </dd>
                        <dd>
                            <a href="#flow6">Whitepaper</a>
                        </dd>
                    </dl>
                </li>
                <li class="links">
                    <dl>
                        <dt>Quick link</dt>
                        <dd>
                            <p class="desc">
                                Subscribe to get updates and notifications<br>on our exchanges and products
                            </p>
                        </dd>
                        <dd>
                            <p class="desc">
                                Email: Admin@freecz.win
                            </p>
                        </dd>
                    </dl>
                </li>
            </ul>

            <footer>© 2024 CZ Coin. All Rights Reserved</footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyFooter',
    data() {
        return {

        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    margin-top: 80px;

    .main {
        width: 100%;
        max-width: 1334px;
        margin: 0 auto;

        .footer_box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                margin-bottom: 20px;
            }
            .item_start {
                display: flex;
                flex-direction: column;
                align-items: start;

                .logo {
                    width: 186px;
                    font-size: 0;

                    img {
                        width: 100%;
                    }
                }

                .iconLink {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    a {
                        width: 50px;
                        margin-right: 12px;
                        font-size: 0;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .links {
                dl {
                    dt {
                        font-size: 25px;
                        font-weight: bold;
                        font-family: 'poppins';
                        color: #fff;
                        text-align: left;
                    }

                    .pb-10 {
                        padding-bottom: 10px;
                    }

                    dd {
                        a {
                            display: block;
                            text-align: left;
                            width: 100%;
                            font-family: 'poppins';
                            color: #fff;
                            font-size: 16px;
                            padding: 5px 0;
                        }
                    }
                }
            }
        }

        footer {
            color: #fff;
            font-size: 16px;
            padding: 25px 0;
            font-family: 'poppins';
            margin-top: 50px;
            border-top: 1px solid #ffffff;
        }
    }

    p.desc {
        color: #fff;
        font-size: 16px;
        text-align: left;
        margin-top: 20px;
        font-family: 'poppins';
    }
}
</style>