<template>
  <div class="container" id="flow2">
    <div class="main">
      <div class="rows_top">
        <div class="left">
          <div class="titLogo">
            <img src="../assets/tonkentit.png" alt="" />
          </div>

          <div class="text_nums">
            <p>Total Supply</p>
            <div class="num">420,690,000,000,000 $CZCOIN</div>
          </div>

          <div class="scheduleBox">
            <div class="item">
              <div class="schedules">
                <div class="fill" :style="fillW1"></div>
              </div>
              <div class="text_col">
                <span>Presale</span>
                <div class="percent">{{ percent1 }}%</div>
              </div>
            </div>
            <div class="item">
              <div class="schedules">
                <div class="fill" :style="fillW2"></div>
              </div>
              <div class="text_col">
                <span>Liquidity</span>
                <div class="percent">{{ percent2 }}%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="pic">
            <img src="../assets/tokenpic.png" alt="" />
          </div>
          <div class="tax">
            <div class="titles">TAX 3/3</div>
            <div class="cols">
              <div class="item">1% Buyback</div>
              <div class="item">2% Marketing</div>
            </div>
          </div>
        </div>
      </div>

      <div class="contract" @click="copyContract">
        <div class="tit">CONTRACT</div>
        <input type="text" v-model="contract" />
        <div class="icon" v-show="contract_show">
          <img src="../assets/copy.png" alt="" />
        </div>
      </div>

      <div class="iconList">
        <div class="iconItem" v-for="(item, index) in iconList" :key="index">
          <a :href="item.link" class="icon">
            <img :src="item.src" alt="" />
          </a>
        </div>
      </div>

      <div class="buynow">
        <img src="../assets/buynow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyTokenmics",
  data() {
    return {
      percent1: 67,
      fillW1: "",
      percent2: 33,
      fillW2: "",
      contract_show: true,
      contract: "0xd8e4C11c1d267D35b159F83b9BeFeFD59d663b47",

      // 图标列表
      iconList: [
        {
          link: "https://www.dextools.io/app/en/bnb/pair-explorer/0x6335b14ab8248049050371d0b5ad3f84a98e0424?t=1723791626013",
          src: "",
        },
        {
          link: "https://dexscreener.com/bsc/0xd8e4c11c1d267d35b159f83b9befefd59d663b47",
          src: "",
        },
        {
          link: "https://ave.ai/token/0xd8e4c11c1d267d35b159f83b9befefd59d663b47-bsc?from=Token",
          src: "",
        },
        {
          link: "https://poocoin.app/tokens/0xd8e4c11c1d267d35b159f83b9befefd59d663b47",
          src: "",
        },
      ],
    };
  },
  methods: {
    // 设置百分比
    setPrecent() {
      this.fillW1 = `--fill-w:${this.percent1}%;--bg-c:#dfba27;`;
      this.fillW2 = `--fill-w:${this.percent2}%;--bg-c:#8a00ff;`;
    },

    // 设置图标列表src地址
    setIconSrc() {
      this.iconList.forEach((item, index) => {
        this.iconList[index].src = `../img/icon${index + 1}.png`;
      });
    },

    copyContract() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.contract);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = this.contract;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }
      const contract_temp = this.contract;
      this.contract = "Copied to Clipboard";
      this.contract_show = false;
      setTimeout(() => {
        this.contract = contract_temp;
        this.contract_show = true;
      }, 500);
    },
  },
  created() {
    this.setPrecent();
    this.setIconSrc();
  },
};
</script>

<style scoped lang="less">
.main {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
}

.contract {
  margin-top: 50px;
}

.container .main {
  .rows_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left {
      width: 55%;

      .titLogo {
        width: 90%;

        img {
          width: 100%;
        }
      }

      .text_nums {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding-left: 15px;
        border-left: 4px solid #dfba27;
        margin-top: 10px;

        p {
          font-size: 20px;
          color: #e7bb41;
          font-weight: bold;
        }

        .num {
          color: #fff;
          font-size: 40px;
          font-family: "BakbakOneRegular";
        }
      }

      .scheduleBox {
        width: 100%;
        margin-top: 50px;

        .item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 34px;

          .schedules {
            flex: 1;
            border: 1px solid #ffffff;

            .fill {
              width: var(--fill-w);
              height: 40px;
              background-color: var(--bg-c);
            }
          }

          .text_col {
            width: 120px;

            span {
              color: #fff;
              font-size: 14px;
              font-family: "poppins";
            }

            .percent {
              font-size: 24px;
              color: #fff;
              font-family: "BakbakOneRegular";
            }
          }
        }
      }
    }

    .right {
      width: 32%;

      .pic {
        width: 100%;
        font-size: 0;

        img {
          width: 100%;
        }
      }

      .tax {
        border: 1px solid #ffffff;
        border-radius: 10px;

        .titles {
          font-size: 32px;
          font-family: "BakbakOneRegular";
          color: #fff;
          background-color: #dfba27;
          border-radius: 10px;
          padding: 5px 0;
        }

        .cols {
          display: flex;
          align-items: center;
          padding: 10px 0;

          .item {
            flex: 1;
            color: #ffffff;
            font-size: 18px;
            font-family: "poppins";
            border-right: 1px solid #ffffff;
          }
        }
      }
    }
  }

  .iconList {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    .iconItem {
      width: 81px;
      margin: 0 10px;

      a {
        display: block;
        width: 100%;
        font-size: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  .buynow {
    width: 190px;
    font-size: 0;
    display: block;
    margin: 30px auto 0;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  .container .main .rows_top {
    flex-direction: column;

    .left {
      width: 100%;

      .titLogo {
        max-width: 450px;
      }

      .text_nums .num {
        font-size: 30px;
      }
    }

    .right {
      width: 100%;

      .pic {
        max-width: 300px;
        width: 60%;
        margin: 30px auto 0;
      }
    }
  }

  .container .main .iconList .iconItem {
    width: 51px;
  }

  .container .main .buynow {
    width: 140px;
  }
}

@media screen and (max-width: 500px) {
  .container .main .rows_top .left .scheduleBox .item .text_col {
    width: 50px;
  }
  .container .main .rows_top .left .scheduleBox .item {
    margin-top: 10px;
  }
  .container .main .rows_top .left .scheduleBox {
    margin-top: 15px;
  }
  .container .main .rows_top .left .text_nums .num {
    text-align: left;
    font-size: 22px;
  }
}
</style>
