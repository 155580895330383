<template>
    <div class="container">
        <div class="community">
            <div class="bg">
                <img src="../assets/borderbg.png" alt="">
            </div>
            <div class="mains">
                <div class="main">
                    <div class="rows">
                        <div class="pic">
                            <img src="../assets/community_pic.png" alt="">
                        </div>
                        <div class="texts">
                            <h2>{{ title }}</h2>
                            <p>Join the CZ COIN community to witness our magical and explosive journey.</p>
                            <a href="https://t.me/FreeCZ_Bin4nce" class="joinnow">
                                <img src="../assets/joinnow.png" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyCommunity',
    data() {
        return {
            title: 'CZ COMMUNITY'
        }
    },
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    position: relative;

    .community {
        padding-top: 150px;
        margin-top: 80px;

        .bg {
            width: 100%;
            font-size: 0;

            img {
                width: 100%;
            }
        }


        .mains {
            width: 100%;
            position: absolute;
            height: 100%;
            left: 0;
            bottom: 0;

            .main {
                width: 100%;
                max-width: 1334px;
                margin: 0 auto;
                height: 100%;

                .rows {
                    height: 100%;
                    display: flex;

                    .pic {
                        height: 100%;
                        font-size: 0;

                        img {
                            height: 100%;
                        }
                    }

                    .texts {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        margin-left: 100px;
                        padding-top: 150px;
                        text-align: left;

                        h2 {
                            position: relative;
                            font-family: 'ABeeZee';
                            color: #E7BB41;
                            font-weight: normal;
                            font-size: 44px;
                            z-index: 1;
                            text-shadow: 2px 0 #fff,
                                -2px 0 #fff,
                                0 2px #fff,
                                0 -2px #fff,
                                2px 2px #fff,
                                -2px -2px #fff,
                                2px -2px #fff,
                                -2px 2px #fff;
                            letter-spacing: 2px;
                        }

                        p {
                            font-size: 18px;
                            color: #fff;
                            font-family: 'poppins';
                            margin-top: 20px;
                        }

                        .joinnow {
                            margin-top: 20px;
                            width: 163px;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:800px) {
    .container .community .mains .main .rows .texts {
        margin-left: 10px;
    }
}

@media screen and (max-width:750px) {
    .container .community .mains .main .rows .pic {
        height: 80%;
    }
}
@media screen and (max-width:650px) {
    .container .community .mains .main .rows {
        flex-direction: column;

        .texts {
            padding-top: 0;
        }
    }
    .container .community {
        padding-top: 0;
        .bg {
            position: absolute;
            top: 40%;
            left: 0;
        }
        .mains {
            position: relative;
            .main .rows .pic {
                height: auto;
                width: 50%;
                max-width: 220px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>