<template>
  <router-view />
</template>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

ul,
ol {
  list-style: none
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'poppins';
  src: url('./assets/font/Poppins.ttf');
}

@font-face {
  font-family: 'Notable';
  src: url('./assets/font/Notable.ttf');
}

@font-face {
  font-family: 'Holtwood';
  src: url('./assets/font/Holtwood.ttf');
}

@font-face {
  font-family: 'JoyRider';
  src: url('./assets/font/JoyRider.ttf');
}

@font-face {
  font-family: 'BakbakOneRegular';
  src: url('./assets/font/BakbakOneRegular.ttf');
}

@font-face {
  font-family: 'Impact';
  src: url('./assets/font/Impact.ttf');
}

@font-face {
  font-family: 'ABeeZee';
  src: url('./assets/font/ABeeZee.ttf');
}

.contract {
  display: flex;
  align-items: center;
  max-width: 880px;
  margin: 30px auto 15px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  padding-right: 15px;

  .icon {
    font-size: 0;
  }

  .tit {
    font-weight: 900;
    font-size: 26px;
    color: #fff;
    padding: 5px 10px;
    background-color: #dfba27;
    border-radius: 6px;
    font-family: 'BakbakOneRegular';
  }

  input {
    flex: 1;
    outline-style: none;
    border: none;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    background-color: transparent;
    text-transform: uppercase;
  }
}

@media screen and (max-width:1250px) {
  .main {
    padding: 0 20px !important;
  }

  header .mains {
    padding: 14px 20px !important;

  }
}


@media screen and (max-width:850px) {
  .contract input {
    font-size: 16px;
  }

  .contract {
    width: 80%;
  }
}

@media screen and (max-width:650px) {
  .contract input {
    font-size: 14px;
  }

  .contract {
    width: 80%;
    padding-right: 5px;

    .tit {
      font-size: 16px;
    }

    .icon {
      width: 20px;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:650px) {
  .contract{
    margin: 10px auto 5px;
  }
}
</style>
