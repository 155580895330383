<template>
    <div class="container">
        <div class="bg">
            <img src="../assets/attractionbg.png" alt="">
        </div>
        <div class="mains">
            <div class="cardItem" style="left: 0;transform:translateY(-25%);">
                <h2>LARGE FANCOMMUNITY</h2>
                <p>CZ currently known and loved by mostanime fans around the world because of his coolnessand powerful
                    strength, Why don't you join us?</p>
            </div>
            <div class="cardItem" style="right:4%;transform:translateY(-15%);">
                <h2>STRONG TEAI</h2>
                <p>CZ COIN was founded by a team of developerswith rich experience in the cryptocurrency field and
                    apassion for anime, So $GoJo is a combination ofboth of the teams passions.</p>
            </div>
            <div class="cardItem" style="left: 10%;bottom:0%">
                <h2>PLAN MASS MARKETING</h2>
                <p>After launching, we will implement a mass marketingplan immediately on multiple platforms, $CZ willbe
                    the most mentioned name in the near future.</p>
            </div>
            <div class="cardItem" style="right: 0;bottom:5%;">
                <h2>STRONG TEAI</h2>
                <p>CZ COIN was founded by a team of developerswith rich experience in the cryptocurrency field and
                    apassion for anime, So $GoJo is a combination ofboth of the teams passions.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyHeader',
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    position: relative;

    .bg {
        width: 100%;
        font-size: 0;

        img {
            width: 100%;
        }
    }

    .mains {
        position: absolute;
        left: 50%;
        top: 0;
        padding-top: 20%;
        transform: translateX(-50%);
        max-width: 1334px;
        width: 100%;
        height: 100%;

        .cardItem {
            position: absolute;
            text-align: left;
            width: 28%;
            background: linear-gradient(to bottom, rgba(178, 178, 178, .3), rgba(76, 76, 76, .15));
            border-radius: 5px;
            padding: 10px 20px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, #b5b4b5, rgba(255, 255, 255, .2), transparent);
                border-radius: 3px;
                clip-path: polygon(-100vmax -100vmax, 100vmax -100vmax, 100vmax 100vmax, -100vmax 100vmax, -100vmax -100vmax, 3px 3px, 3px calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 3px, 3px 3px);
            }

            h2 {
                font-size: 25px;
                font-family: 'Impact';
                font-weight: normal;
                color: #E7BB41;
            }

            p {
                margin-top: 15px;
                color: #fff;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width:1250px) {
    .container .mains .cardItem {
        position: relative;
        transform: none !important;
        top: auto !important;
        left: auto !important;
        bottom: auto !important;
        right: auto !important;
        width: 48%;
        margin: 20px 0;
    }

    .container .mains {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

    }
}

@media screen and (max-width:1000px) {
    .container .mains {
        padding-top: 8%;
        .cardItem {
            padding: 10px 15px;
            h2 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
                line-height: 1.3;
            }
        }
    }
}
@media screen and (max-width:780px) {
    .container .bg {
        padding-bottom: 100px;
    }
}
@media screen and (max-width:580px) {
    .container .bg {
        display: flex;
        justify-content: center;
        overflow: hidden;
        img {
            width: 155%;
        }
    }

    .container .mains {
        padding-top: 11%;
    }
    .container .community .mains .main .rows .texts h2 {
        font-size: 30px;
    }
}
@media screen and (max-width:450px) {
    .container .bg {
        padding-bottom: 260px;
    }
}
</style>