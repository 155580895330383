<template>
    <div class="container" id="flow4">
        <div class="main">
            <div class="texts">
                <div class="title">
                    <img src="../assets/ROADMAP.png" alt="">
                </div>
            </div>

            <div class="links">
                <dl v-for="(item, index) in roadmapLink" :key="index">
                    <dt><span>{{ item.tit }}</span></dt>
                    <dd v-for="(items, indexs) in item.list" :key="indexs">
                        <a :href="items.href" v-html="items.name"></a>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyROADMAP',
    data() {
        return {
            roadmapLink: [
                {
                    tit: 'PHASE 1',
                    list: [
                        {
                            name: 'Build websites',
                            href: '#'
                        },
                        {
                            name: 'Build Social (Telegram, X, ...)',
                            href: '#'
                        },
                        {
                            name: 'Build Dapp & INO CZ NFT',
                            href: '#'
                        },
                        {
                            name: 'Audit Smart Contract',
                            href: '#'
                        },
                        {
                            name: 'Presale on Pinksale',
                            href: '#'
                        },
                        {
                            name: 'Postlaunch Marketing',
                            href: '#'
                        },
                        {
                            name: 'Audit by Certik',
                            href: '#'
                        },
                    ]
                },
                {
                    tit: 'PHASE 2',
                    list: [
                        {
                            name: 'Launch on Pancakeswap',
                            href: '#'
                        },
                        {
                            name: 'Listing on DEXs',
                            href: '#'
                        },
                        {
                            name: 'Massive marketing',
                            href: '#'
                        },
                        {
                            name: 'Top Trending DEXs Campaign',
                            href: '#'
                        },
                        {
                            name: 'Listing on CoinMarketCap',
                            href: '#'
                        },
                        {
                            name: 'Listing on CoinGecko',
                            href: '#'
                        },
                        {
                            name: 'Announcement of major partners',
                            href: '#'
                        },
                        {
                            name: 'Reach 2,000 Holders',
                            href: '#'
                        },
                    ]
                },
                {
                    tit: 'PHASE 3',
                    list: [
                        {
                            name: 'Expand markets and partners',
                            href: '#'
                        },
                        {
                            name: 'Reach 300,000 people across BSC<br/>platform',
                            href: '#'
                        },
                        {
                            name: 'Many CEX listing',
                            href: '#'
                        },
                        {
                            name: 'Release CZ Staking',
                            href: '#'
                        },
                        {
                            name: 'CZ store shop<br/>Reach +10k holders',
                            href: '#'
                        },
                        {
                            name: 'Build websites',
                            href: '#'
                        },
                        {
                            name: 'Build websites',
                            href: '#'
                        },
                    ]
                },
            ]
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    margin-top: 80px;

    .main {
        width: 100%;
        max-width: 1334px;
        margin: 0 auto;

        .links {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: start;
            margin-top: 40px;

            dl {
                dt {
                    padding-bottom: 15px;
                    display: flex;
                    justify-content: start;

                    span {
                        display: inline-block;
                        text-align: center;
                        background: linear-gradient(to right, rgba(223, 186, 39, .25), transparent);
                        padding: 5px 20px 8px;
                        color: #fff;
                        font-size: 22px;
                        border-left: 3px solid #dfba27;
                        font-family: 'Notable';
                    }
                }

                dd {
                    width: 100%;

                    a {
                        display: block;
                        width: 100%;
                        text-align: left;
                        color: #fff;
                        font-family: 'poppins';
                        margin-bottom: 5px;

                        &:hover {
                            color: #dfba27;
                        }
                    }
                }
            }
        }
    }

    .texts {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}
</style>