<template>
    <header>
        <div class="mains">
            <div class="logo">
                <a href="#">
                    <img src="../assets/logo.png" alt="">
                </a>
            </div>
            <nav v-show="showNav">
                <ul class="nav">
                    <li>
                        <a href="#flow1">Home</a>
                    </li>
                    <li>
                        <a href="#flow2">Tokenomics</a>
                    </li>
                    <li>
                        <a href="#flow3">Partners</a>
                    </li>
                    <li>
                        <a href="#flow4">Roadmap</a>
                    </li>
                    <li>
                        <a href="#flow6">Whitepaper</a>
                    </li>
                </ul>
            </nav>
            <div class="right">
                <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xd8e4c11c1d267d35b159f83b9befefd59d663b47" class="buyBtn">
                    <img src="../assets/buy.png" alt="">
                </a>

                <div class="menu" v-show="showMenu" @click="menuClick">
                    <img src="../assets/menu.png" alt="">
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'MyHeader',
    data() {
        return {
            showNav: true,
            showMenu: false,
        }
    },
    methods: {
        handleResize() {
            if (window.innerWidth < 1000) {
                this.showNav = false
                this.showMenu = true
            } else {
                this.showNav = true
                this.showMenu = false
            }
        },
        menuClick() {
            this.showNav = !this.showNav
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    created() {
        this.handleResize()
    },
}
</script>

<style scoped lang="less">
header {
    width: 100%;
    background-color: #050108;

    .mains {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        padding: 14px 0;

        .right {
            display: flex;
            align-items: center;

            .menu {
                position: relative;
                z-index: 99;
                width: 24px;
                font-size: 0;
                margin-left: 15px;

                img {
                    width: 100%;
                }
            }
        }

        .logo {
            width: 186px;
            font-size: 0;

            a {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .buyBtn {
            width: 174px;
            font-size: 0;

            img {
                width: 100%;
            }
        }

        nav {
            .nav {
                display: flex;
                align-items: center;

                li a {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-style: italic;
                    font-family: 'poppins';
                    padding: 0 20px 0 10px;

                    &:hover {
                        color: #dfba27;
                    }

                    &::before {
                        content: '';
                        width: 5px;
                        height: 5px;
                        margin-right: 10px;
                        background-color: #fff;
                        border-radius: 100%
                    }
                }

            }
        }
    }
}

@media screen and (max-width:1000px) {
    header .mains .buyBtn {
        width: 130px;
    }

    header .mains .logo {
        width: 130px;
    }

    header .mains nav {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9;

        .nav {
            flex-direction: column;
            background-color: rgba(255, 255, 255, .7);
            backdrop-filter: blur(8px);
            padding: 40px 20px 30px;
            border-radius: 0 0 15px 15px;

            li a {
                padding: 10px 0;
                color: #000;

                &::before {
                    display: none;
                }
            }
        }

    }
}
</style>