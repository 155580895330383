<template>
    <div class="container" id="flow3">
        <div class="main">
            <div class="texts">
                <div class="title">
                    <img src="../assets/PARTNERS.png" alt="">
                </div>
                <div class="desc">
                    Continuously expanding our presence and connecting more allies is key to
                    our success. Partners will be continuously updated and we will excite the
                    community.
                </div>
            </div>

            <div class="iconList">
                <div class="itemIcon" v-for="(item, index) in iconList" :key="index">
                    <a :href="item.href">
                        <img :src="item.src" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyPartners',
    data() {
        return {
            iconList: [{
                href: '#',
                src: ''
            }, {
                href: '#',
                src: ''
            }, {
                href: '#',
                src: ''
            }, {
                href: 'https://www.dextools.io/app/en/bnb/pair-explorer/0x6335b14ab8248049050371d0b5ad3f84a98e0424?t=1723791626013',
                src: ''
            }, {
                href: 'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xd8e4c11c1d267d35b159f83b9befefd59d663b47',
                src: ''
            }, {
                href: '#',
                src: ''
            }, {
                href: 'https://bscscan.com/token/0xd8e4c11c1d267d35b159f83b9befefd59d663b47',//BSC
                src: ''
            }, {
                href: '#',
                src: ''
            }, {
                href: 'dexview.com/bsc/0xd8e4C11c1d267D35b159F83b9BeFeFD59d663b47',//dexview
                src: ''
            }, {
                href: 'https://ave.ai/token/0xd8e4c11c1d267d35b159f83b9befefd59d663b47-bsc?from=Token',//AVE
                src: ''
            },]
        }
    },
    methods: {
        setIconList() {
            this.iconList.forEach((item, index) => {
                this.iconList[index].src = `../img/par${index}.png`
            })
        }
    },
    created() {
        this.setIconList()
    }
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    margin-top: 80px;

    .main {
        width: 100%;
        max-width: 1334px;
        margin: 0 auto;
    }

    .iconList {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .itemIcon {
            width: 20%;
            padding: 10px 0;
        }
    }

    .texts {
        display: flex;
        flex-direction: column;
        align-items: start;

        .desc {
            margin-top: 20px;
            width: 52%;
            color: #fff;
            text-align: left;
            font-size: 18px;
        }
    }
}

@media screen and (max-width:900px) {
    .container .iconList .itemIcon {
        display: flex;
        justify-content: center;
        a {
            display: block;
            width: 90%;
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:650px) {
    .container .iconList .itemIcon {
        width: 25%;
        padding: 0;
    }
    .container .texts .desc {
        width: 100%;
        margin-top: 15px;
    }
}
</style>