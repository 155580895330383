<template>
  <div class="home">
    <MyHeader/>
    <MyHome/>
    <MyToken/>
    <MyAttraction/>
    <MyCommunity/>
    <MyPartners/>
    <MyROADMAP/>
    <MyFooter/>
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue'
import MyHome from '@/components/MyHome.vue'
import MyToken from '@/components/MyToken.vue'
import MyAttraction from '@/components/MyAttraction.vue'
import MyCommunity from '@/components/MyCommunity.vue'
import MyPartners from '@/components/MyPartners.vue'
import MyROADMAP from '@/components/MyROADMAP.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'HomeView',
  components: {
    MyHeader,
    MyHome,
    MyToken,
    MyAttraction,
    MyCommunity,
    MyPartners,
    MyROADMAP,
    MyFooter
  }
}
</script>

<style scoped>
.home {
  background-color: #050108;
}
</style>