<template>
  <div class="container" id="flow1">
    <div class="bg">
      <img src="../assets/homeBg.png" alt="" />
    </div>
    <div class="container_box">
      <h1>WELCOMEO FreeCZ（CZ）</h1>
      <h3>open the world of magic with $czcoin</h3>
      <p>
        Don't Worry, I'm the Strongest" was CZ's first words when entering
        the<br />crypto field. CZ COIN will protect and bring all investors to
        "Infinity".
      </p>
      <div class="contract" @click="copyContract">
        <div class="tit">CONTRACT</div>
        <input type="text" v-model="contract" />
        <div class="icon" v-show="contract_show">
          <img src="../assets/copy.png" alt="" />
        </div>
      </div>
      <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xd8e4c11c1d267d35b159f83b9befefd59d663b47" class="buyon">
        <img src="../assets/buyon.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyHome",
  data() {
    return {
      contract: "0xd8e4c11c1d267d35b159f83b9befefd59d663b47",
      contract_show:true,
    };
  },

  methods: {
    copyContract() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.contract);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = this.contract;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }

      const contract_temp = this.contract;
      this.contract = "Copied to Clipboard";
      this.contract_show = false;
      setTimeout(() => {
        this.contract = contract_temp;
        this.contract_show = true;
      }, 500);
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 100%;
  position: relative;
  background-color: #050108;

  .bg {
    width: 100%;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .container_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding-top: 7%;

    h1 {
      font-family: "Holtwood";
      font-size: 60px;
      font-weight: normal;
    }

    h3 {
      color: #f9ef21;
      font-size: 23px;
      font-family: serif;
      font-family: "JoyRider";
      font-weight: normal;
      letter-spacing: 2px;
      margin-top: -10px;
    }

    p {
      font-size: 18px;
      margin-top: 20px;
    }

    .buyon {
      display: block;
      width: 260px;
      font-size: 0;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .container .container_box {
    h1 {
      font-size: 40px;
    }

    h3 {
      font-size: 18px;
    }

    .contract input {
      font-size: 16px;
    }

    .contract {
      width: 80%;
    }

    .buyon {
      width: 180px;
    }
  }
}

@media screen and (max-width: 650px) {
  .container .container_box {
    h1 {
      font-size: 30px;
      line-height: 32px;
    }

    h3 {
      font-size: 16px;
      margin-top: 0;
      letter-spacing: 0;
    }

    p {
      font-size: 14px;
      margin-top: 12px;
    }

    .contract input {
      font-size: 14px;
    }

    .contract {
      width: 80%;

      .tit {
        font-size: 16px;
      }

      .icon {
        width: 20px;

        img {
          width: 100%;
        }
      }
    }

    .buyon {
      width: 180px;
    }
  }

  .container .bg {
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      width: 135%;
    }
  }
}

@media screen and (max-width: 400px) {
  .container .container_box .buyon {
    width: 110px;
  }
}
</style>
